import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BackgroundImage from 'gatsby-background-image'
import pdf from '../../images/cassieprogram.pdf'
import elevate from '../../images/cassies-ballad/ELEVATE Atlanta Art Festival - word logo.png'
import wawa from '../../images/cassies-ballad/wawa_logo_color_final.png'
import hhl from '../../images/cassies-ballad/HHL-logo.png'
import atl from '../../images/cassies-ballad/atl.png'
import CNP from '../../images/cassies-ballad/CNP.png'
import FCAC from '../../images/cassies-ballad/FCAC-Logo.png'
import GCA from '../../images/cassies-ballad/GCA-Logo_large.png'

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    productionsWrapper: {
      paddingBottom: "1em",
      maxWidth: "75rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    showsWrapper: {
      paddingBottom: "1em",
      maxWidth: "60rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    button: {
      margin: theme.spacing(1),
    },
    jumbo: {
      height: "100vh",
      backgroundSize: "cover",
      backgroundPosition: "right"
    },
  }));

export default function CassiesBallad() {
  const data = useStaticQuery(graphql`
  query {
    desktop: file(relativePath: { eq: "cassies-ballad/cassie-web-banner.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mobile: file(relativePath: { eq: "cassies-ballad/cassieBalladHeroCropped.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
  }
`)

const sources = [
    data.desktop.childImageSharp.fluid,
    {
      ...data.mobile.childImageSharp.fluid,
      media: `(max-width: 720px)`,
    },
  ]

  const classes = useStyles();

  return (
    <Layout>
        <SEO title="Cassie's Ballad" />
        <BackgroundImage 
        Tag="section"
        className={classes.jumbo}
        fluid={sources}
        backgroundColor={`#040e18`}
        >
          <div class="jumbo-inner-wrapper-cassie">
              <div>
                <h1 class="jumbo-title-wine">
                    Cassie's Ballad
                </h1>
                <h5 class="subtitle-wine">An Immersive Ritual and Historical Fable</h5>
                {/* <br></br><br></br>
                Oct 1st & 2nd
                </h5>
                
                <div class="wine-buttons">
                  <a title="Get Tickets" href="https://www.eventbrite.com/e/cassies-ballad-at-elevate-festival-tickets-418386855347" class="button button--bigger blackButton--border">
                    Get Tickets
                  </a>
                  <a title="Become a Supporter" href="https://secure.givelively.org/donate/found-stages-inc" class="button button--bigger blackButton--border">
                    Become a Supporter
                    </a>
                </div> */}
              </div>
          </div>
        </BackgroundImage>
        <div>
        <div class="frankenstein-content-block">
            <h3 class="frankenstein-description">
            Cassie’s Ballad follows the story of Brandon, a young Black teen living in Atlanta during the early 1980s. His friend Cassie has lost her voice after a child they know has gone missing. With the guidance of an Ancestor, Brandon leads the audience on a literal search for the missing child and a figurative search for Cassie’s voice. From the “Graveyard of Innocence” to Cassie’s Bottle Tree, the journey leads them to a sad but healing discovery.
            </h3>
        </div>  
        </div>
        <div className="cassie-program-row">
          <div class="not-wine-buttons">
              <a title="Digital Program" href={pdf} target="_blank" class="button button--bigger blackButton--border">
                Digital Program
              </a>
              <a title="Post-hike Meditation" href="https://soundcloud.com/hush-harbor-lab/cassies-ballad-meditation-with-music?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" target="_blank" class="button button--bigger blackButton--border">
                Post-hike Meditation
              </a>
            </div>
        </div>
        <div class="podplay-row">
            <iframe class="i-frame" title="Cassie's Ballad" src="https://www.youtube.com/embed/RGhTgwhKJzM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Cast
            </div>
        </div>
        <div class="content-box-row">
          <div class="wrapper">
              <div class="top-cb cb-card-1">
                  </div>
                  <div class="bottom">
                      <h4 class="card-title">Ancestor</h4>
                      {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                      <p class="production-card__locations">Mia Kristin Smith</p>

                </div>
            </div>
            <div class="wrapper">
                <div class="top-cb cb-card-2">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">Brandon</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">Kcaj Walker Smith</p>
                </div>
            </div>
            <div class="wrapper">
                <div class="top-cb cb-card-3">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">Brandon</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">Kyrun Walker Smith</p>
                </div>
            </div>
            <div class="wrapper">
                <div class="top-cb cb-card-4">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">Mrs. Jeffers</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">Antonia LaChé</p>
                </div>
            </div>
            <div class="wrapper">
                <div class="top-cb cb-card-5">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">Cassie</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">Yakini Horn</p>
                </div>
            </div>
            <div class="wrapper">
                <div class="top-cb cb-card-6">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">Terrance</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">Anthony Nash</p>
                </div>
            </div>
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Crew
            </div>
        </div>
        <div style={{maxWidth: '80%', margin: '0 auto 40px auto', display:'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column', fontSize: 24, lineHeight: '1.5'}}>
          <p class="card-title">
            <span style={{fontWeight: 'bold'}}>Production Manager</span> – Jennifer Kimball
          </p>
          <p class="card-title">
          <span style={{fontWeight: 'bold'}}>Stage Manager</span> – Liz Connell
          </p>
          <p class="card-title">
          <span style={{fontWeight: 'bold'}}>Run Crew</span> – Kailee Wang
          </p>
          <p class="card-title">
          <span style={{fontWeight: 'bold'}}>Box Office Manager</span> – Kayla Parker
          </p>
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Creative Team
            </div>
        </div>
        <div style={{maxWidth: '80%',margin: '0 auto 40px auto', display:'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column', fontSize: 24, lineHeight: '1.5'}}>
          <p class="card-title">
            <span style={{fontWeight: 'bold'}}>Playwright</span> – Addae Moon
          </p>
          <p class="card-title">
          <span style={{fontWeight: 'bold'}}>Director</span> – Lydia Fort
          </p>
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Production Team
            </div>
        </div>
        <div style={{maxWidth: '80%', margin: '0 auto 40px auto', display:'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column', fontSize: 24, lineHeight: '1.5'}}>
          <p class="card-title">
            <span style={{fontWeight: 'bold'}}>Producers</span> – Nichole Palmietto, Neeley Gossett, Addae Moon, Amina McIntyre &amp; Lydia Fort
          </p>
          <p class="card-title">
          <span style={{fontWeight: 'bold'}}>May 2022 Production Designers</span> – Masud Olufani (Scenic), Joey Swift (Lighting), Winston
          Johnson (Sound), Nyrobi Moss (Costumes), Rachel Graf Evans (Props) &amp; Angela Harris
          (Choreography)
          </p>
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Marketing Team
            </div>
        </div>
        <div style={{maxWidth: '80%', margin: '0 auto 40px auto', display:'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column', fontSize: 24, lineHeight: '1.5'}}>
          <p class="card-title">
            <span style={{fontWeight: 'bold'}}>Marketing Manager</span> – Precious West
          </p>
          <p class="card-title">
          <span style={{fontWeight: 'bold'}}>Graphic Designer</span> – Christopher Fairchild
          </p>
          <p class="card-title">
          <span style={{fontWeight: 'bold'}}>Web Designer</span> – Josh Akeman
          </p>
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Production Partners
            </div>
        </div>
        <div className='crew-gallery'>
          <div className="crew-card">
            <img src={wawa} />
          </div>
          <div className="crew-card">
            <img src={hhl} />
          </div>
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Production Sponsors
            </div>
        </div>
        <div className='crew-gallery'>
          <div className="crew-card">
            <img src={atl} />
          </div>
          <div className="crew-card">
            <img src={CNP} />
          </div>
          <div className="crew-card">
            <img src={FCAC} />
          </div>
          <div className="crew-card">
            <img src={GCA} />
          </div>
        </div>
        <div style={{maxWidth:'80%', margin: '20px auto', fontSize:'28px', lineHeight: '1.25'}}>
          <p>Funding for Cassie’s Ballad is provided by the Fulton County Board of Commissioners, and is supported in part by the City of Atlanta Mayor’s Office of Cultural Affairs and by Georgia Council for the Arts through the appropriations of the Georgia General Assembly. Georgia Council for the Arts also receives support from its partner agency – the National Endowment for the Arts.</p>
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Festival Sponsor
            </div>
        </div>
        <div style={{display:'flex', justifyContent: 'center'}}>
          <img style={{maxWidth:'60%'}} src={elevate} />
        </div>
        <div class="lesspadding-title-wrapper">
            <div class="productions-title">
                Location
            </div>
        </div>
        <div className="cassie-performance-dates">
            <h1 className="cassie-time-heading">
                <a href="https://www.wawa-online.org/">West Atlanta Watershed Alliance</a>
            </h1>
        </div>
    </Layout>
  );
}
